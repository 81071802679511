import React, { useState, KeyboardEvent } from 'react';
import { Container, Row, Col, Button, InputGroup, InputGroupText, Input, Card, CardBody, Spinner } from 'reactstrap';
import axios from 'axios';

const ChatInterface = () => {
  const [messages, setMessages] = useState<{ sender: string; text: string }[]>([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // State to track loading

  const handleSendMessage = async () => {
    if (inputMessage.trim() !== '') {
        setIsLoading(true);
        const updatedMessages = [...messages, { sender: 'user', text: inputMessage }];
        setMessages(updatedMessages);
        try {
            const response = await axios.post('https://api.chatnft.ai/chat', { message: inputMessage });
            updatedMessages.push({ sender: 'ai', text: response.data });
            setMessages(updatedMessages);
        } catch (error) {
            console.error('Error fetching response', error);
        } finally {
            setIsLoading(false);
        }
        setInputMessage('');
    }
};

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputMessage(event.target.value);
  };

  return (
    <Container className="mt-5">
      <Row className="mt-1">
        <Col md="6" sm="10" className="text-center">
          <h1 className="display-4 font-weight-normal text-white">Chat with your NFT</h1>
          <img src="chatbots_0001.jpg" className="w-75 mt-5" alt="NFT Avatar" />
        </Col>
        <Col md="4" sm="8" className="offset-md-1">
          <Card style={{ backgroundColor: '#1c1c1e', borderRadius: '15px', boxShadow: '0 4px 8px rgba(0,0,0,0.2)' }}>
          <CardBody style={{ maxHeight: '500px', overflowY: 'auto' }}>
            {messages.map((message, index) => (
              <div key={index} className={`mb-2 ${message.sender === 'user' ? 'text-right' : 'text-left'}`}>
                <span className={`badge ${message.sender === 'user' ? 'badge-info' : 'badge-light'}`} style={{ 
                  padding: '10px', 
                  borderRadius: '20px', 
                  backgroundColor: message.sender === 'user' ? '#0d6efd' : 'white', 
                  color: message.sender === 'user' ? 'white' : 'black',
                  wordWrap: 'break-word',
                  whiteSpace: 'normal',
                  display: 'inline-block',
                  maxWidth: '100%',
                  textAlign: 'left',
                }}>
                  <strong>{message.sender === 'user' ? 'User: ' : 'AI: '}</strong>{message.text}
                </span>
              </div>
            ))}
            {isLoading && (
              <div className="text-center">
                <Spinner style={{ width: '3rem', height: '3rem' }} />
              </div>
            )}
          </CardBody>
          </Card>
          <InputGroup style={{ marginTop: '10px' }}>
            <Input
              type="text"
              placeholder="Type your message"
              value={inputMessage}
              onChange={handleInputChange}
              onKeyPress={(event: KeyboardEvent<HTMLInputElement>) => {
                if (event.key === 'Enter' && !event.shiftKey) {
                  event.preventDefault();
                  handleSendMessage();
                }
              }}
              style={{ marginTop: '10px' }}
            />
            <InputGroupText>
              <Button color="primary" onClick={handleSendMessage}>
                Send
              </Button>
            </InputGroupText>
          </InputGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default ChatInterface;

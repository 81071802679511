// chatnft/frontend/src/components/App.tsx
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import Footer from './components/Footer';
import ChatInterface from './components/ChatInterface';

function App() {
  return (
    <div className="d-flex flex-column min-vh-100 text-white bg-black">
      <Header />
      <main className="flex-fill">
        <ChatInterface />
      </main>
      <Footer />
    </div>
  );
}

export default App;
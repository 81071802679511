import React from 'react';

const Header = () => {
  return (
    <header className="text-white bg-black text-center py-3">
      <h1>ChatNFT</h1>
    </header>
  );
};

export default Header;